<script setup lang="ts">
const props = defineProps({
	lng: { type: [String, Number], required: true },
	lat: { type: [String, Number], required: true },
	width: { type: [String, Number], default: '100%' },
	height: { type: [String, Number], default: 450 },
	addMark: { type: Boolean, default: false },
});

const mapsApiKey = useRuntimeConfig().public.mapsApiKey;

const formatUrl = () => {
	return props.addMark
		? `https://www.google.com/maps/embed/v1/place?q=${props.lat},${props.lng}&key=${mapsApiKey}`
		: `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d27647.70812568088!2d${props.lng}9999994!3d${props.lat}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2seg!4v1716980595159!5m2!1sen!2seg`;
};
</script>

<template>
	<iframe :src="formatUrl()" :width="props.width" :height="props.height" style="border:0;" allowfullscreen="true" loading="lazy"
		referrerpolicy="no-referrer-when-downgrade">
	</iframe>
</template>
